import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { userLoggedIn } from '../../_redux/actions';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setUserLoggedIn: (data) => {
    dispatch(userLoggedIn(data));
  },
});

function ConfirmLogoutModal(props) {
  const { setUserLoggedIn, closeModal, show } = props;
  const router = useRouter();

  function logout() {
    Cookies.remove('_access_token');
    setUserLoggedIn(false);
    router.push('/');
    closeModal();
  }

  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="xs"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="login-modal order-confirm-modal p-0"
    >
      <Modal.Header closeButton className="border-0 align-items-center">
        <Modal.Title id="contained-modal-title-vcenter" className="font-proxima fs-26 fw-600 text-center w-100 pb-3">
          Attention !
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center px-4">
          <div className="fs-24">
            <p>Are you sure, want to logout?</p>
          </div>

          <div className="text-center">
            <button type="button" className="search-btn py-2 px-3 fs-4 rounded mt-3 text-center" onClick={logout}>
              OK
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmLogoutModal);
